import React from 'react';
import { Link } from 'gatsby';

const classes = {
  heading: 'text-md text-gray-700',
  link: 'inline text-gray-600 ml-1 hover:text-black',
};

const SectionTag = ({ tags }) => {
  if (!tags.length) return null;

  return (
    <div>
      <span className={classes.heading}>
        Tags:
        {tags.map((tag) => (
          <Link key={tag} className={classes.link} to={`/tags/${tag}`}>
            {tag}
          </Link>
        ))}
      </span>
    </div>
  );
};

export default SectionTag;
